import React from 'react'
import './termsAndPolicy.css'


const PrivacyPolicy= () => {
    return (
        <div className="privacy-policy">
            <h1 className="align-center">EAT ID Privacy Policy</h1>
            <p className="align-center">Last Updated: February 5 2023</p>

            <div className="section">
                <p>This privacy policy (“<b>Privacy Policy</b>”) governs how we, EAT ID Ltd.(together, “<b>EAT</b>” “<b>we</b>”, “<b>our</b>” or “<b>us</b>”) use, collect and store Personal Data we collect or receive from or about you (“<b>you</b>”) such as in the following use cases:</p>
                <ol type="i">
                    <li>When you browse or visit our website, https://www.eat.vision/ (“<b>Website</b>”)</li>
                    <li>When you make use of, or interact with, our Website
                        <ol type="a">
                            <li>When you register to receive additional information of EAT and its Service</li>
                        </ol>
                    </li>
                    <li>When you are an end user and make use of our Virtual Waiter products and services (“<b>Service</b>”)
                        <ol type="a">
                            <li>When you are an end user and you create an account and log-in to our Service</li>
                            <li>When you make use of your Service as a guest (without creating an account)</li>
                        </ol>
                    </li>
                    <li>When you attend a marketing event, conferences and/or we exchange business cards and you provide us with your Personal Data</li>
                    <li>When we use the Personal Data of our partner establishments / restaurants (e.g. contact details)</li>
                    <li>When we use the Personal Data of our service providers (e.g. contact details)</li>
                    <li>When you interact with us on our social media profiles (e.g., LinkedIn)</li>
                </ol>
            </div>

            <div className="section">
                <p>Please read this Privacy Policy carefully, so you can understand our practices and your rights in relation to personal data. “<b>Personal Data</b>” or “<b>Personal Information</b>” means any information that can be used, alone or together with other data, to uniquely identify any living human being and any information deemed as Personally Identifiable Information by privacy laws. Please note that this is a master privacy policy and some of its provisions only apply to individuals in certain jurisdictions. For example, the legal basis in the table below is only relevant to GDPR-protected individuals.</p>
                <p><strong>Important note:</strong> Nothing in this Privacy Policy is intended to limit in any way your statutory right, including your rights to a remedy or means of enforcement.</p>
            </div>

            <div className="section">
                <h2>Table of contents:</h2>
                    <li>What information we collect, why we collect it, and how it is used</li>
                    <li>How we protect and retain your Personal Data</li>
                    <li>How we share your Personal Data</li>
                    <li>Additional information regarding transfers of Personal Data</li>
                    <li>Your privacy rights</li>
                    <li>Use by children</li>
                    <li>Interaction with third party products</li>
                    <li>Log files</li>
                    <li>Analytic tools</li>
                    <li>Specific provisions applicable under California law</li>
                    <li>Contact us</li>
            </div>

            <div className="section">
                <p>This Privacy Policy can be updated from time to time and, therefore, we ask you to check back periodically for the latest version of this Privacy Policy. If we implement significant changes to the use of your Personal Data in a manner different from that stated at the time of collection, we will notify you by posting a notice on our Website or by other means.</p>
            </div>

            <div className="section">
                <h2>1. WHAT INFORMATION WE COLLECT, WHY WE COLLECT IT, AND HOW IT IS USED</h2>
                <strong>1.1 When you browse or visit our Website</strong>
                    <ol type="a">
                        <li>Specific Personal Data we collect</li>
                        <ul>
                            <li>Cookies, analytic tools and log files</li>
                        </ul>
                        <li>Why is the Personal Data collected and for what purposes?</li>
                            <ul>
                                <li>To collect and store information</li>
                                <li>To review or improve the usage and operations of our Website</li>
                                <li>To analyze trends</li>
                                <li>To administer the Website</li>
                                <li>To track users’ movement around the Website</li>
                                <li>To gather demographic information</li>
                                <li>To provide you with customized content, targeted offers, and advertising on the Website and across other third-party sites, and to determine the effectiveness of promotional campaigns</li>
                            </ul>
                        <li>Legal basis (GDPR only, if applicable)</li>
                            <ul>
                                <li>Legitimate interest (e.g., essential cookies)</li>
                                <li>Consent (obtained via the cookie consent mechanism)</li>
                            </ul>
                        <li>Third parties with whom we share your Personal Data</li>
                            <p>3rd party platforms such as for the following purposes:</p>
                            <ul>
                                <li>Wix (website provider)</li>
                            </ul>
                        <li>Consequences of not providing the Personal Data</li>
                        <p>Certain non-essential Website features may not be available</p>
                    </ol>

                    <strong>1.2 When you make use of, or interact with, our Website</strong>
                    <strong>or When you register to receive additional information of EAT and its Service</strong>
                    <ol type="a">
                        <li>Specific Personal Data we collect</li>
                        <ul>
                            <li>Email address</li>
                        </ul>
                        <li>Why is the Personal Data collected and for what purposes?</li>
                            <ul>
                                <li>To send you news and information of EAT and its Services</li>
                            </ul>
                        <li>Legal basis (GDPR only, if applicable)</li>
                            <ul>
                                <li>Legitimate interest (e.g., essential cookies)</li>
                            </ul>
                        <li>Third parties with whom we share your Personal Data</li>
                            <p>3rd party platforms such as for the following purposes:</p>
                            <ul>
                                <li>Wix (website provider)</li>
                            </ul>
                        <li>Consequences of not providing the Personal Data</li>
                        <p>Cannot send news and information of EAT and its Services</p>
                    </ol>
                    <strong>1.3 When you are an end user and make use of our Virtual Waiter products and services</strong>
                    <strong>or When you are an end user and you create an account and log-in to our Service</strong>
                    <ol type="a">
                        <li>Specific Personal Data we collect</li>
                        <ul>
                            <li>Phone number</li>
                            <li>Diets, food preferences, allergies and other health conditions (e.g., pregnancy)</li>
                            <li>Food establishment and the proposed dishes available for you</li>
                            <li>Time of your visit / Time stamp</li>
                            <li>Any other information that you decide to provide us with</li>
                        </ul>
                        <li>Why is the Personal Data collected and for what purposes?</li>
                            <ul>
                                <li>To be able to create an account</li>
                                <li>To allow you to log in</li>
                                <li>To send you an SMS to verify your account</li>
                                <li>To allow you to enjoy features available in our Service to registered users only</li>
                                <li>To offer you the relevant dishes and changes based on you preferences</li>
                                <li>To customize your experience</li>
                                <li>To create statistical and aggregated information (de-identified data)</li>
                            </ul>
                            <p>Important note: If you have any allergies, please remember that you should ALWAYS mention such allergies to the restaurant.</p>
                        <li>Legal basis (GDPR only, if applicable)</li>
                            <ul>
                            <li>Performance of a contract (your acceptance of our terms and conditions <a href="https://www.eat-id.eat.vision//terms-of-use">EAT ID Terms and condition</a>)</li>
                            <li>Legitimate interest (e.g., to allow you to register)</li>
                            </ul>
                        <li>Third parties with whom we share your Personal Data</li>
                            <p>3rd party platforms such as for the following purposes:</p>
                            <ul>
                                <li>MongoDB (as a cloud and log-in feature)</li>
                                <li>Twillio (SMS provider)</li>
                            </ul>
                            <p>Important note: We are not sharing your Personal Data with the food establishments, unless you specifically grant us the permission to do so (e.g., we inform the restaurant that you do not have any dish to offer this person/table, but we do not share the reasons).</p>
                        <li>Consequences of not providing the Personal Data</li>
                        <li>Cannot be able to create an account</li>
                                <li>Cannot allow you to log in</li>
                                <li>Cannot send you an SMS to verify your account</li>
                                <li>Cannot allow you to enjoy features available in our Service to registered users only</li>
                                <li>Cannot offer you the relevant dishes and changes based on you preferences</li>
                                <li>Cannot customize your experience</li>
                                <li>Cannot create statistical and aggregated information (de-identified data)</li>
                
                    </ol>
                    <strong>1.4 When you make use of your Service as a guest (without creating an account)</strong>
                    <ol type="a">
                        <li>Specific Personal Data we collect</li>
                        <ul>
                            <li>Diets, food preferences and allergies</li>
                            <li>Diets, food preferences, allergies and other health conditions (e.g., pregnancy)</li>
                            <li>Time of your visit / Time stamp</li>
                            <li>Any other information that you decide to provide us with</li>
                        </ul>
                        <li>Why is the Personal Data collected and for what purposes?</li>
                            <ul>
                                <li>To allow you to make use of our Service</li>
                                <li>To offer you the relevant dishes and changes based on you preferences</li>
                                <li>To customize your experience</li>
                                <li>To create statistical and aggregated information (de-identified data)</li>
                            </ul>
                        <li>Legal basis (GDPR only, if applicable)</li>
                            <ul>
                                <li>Performance of a contract (your acceptance of our terms and conditions <a href="https://www.eat-id.eat.vision//terms-of-use">EAT ID Terms and condition</a>)</li>
                                <li>Legitimate interest (e.g., to allow you to make use of the Service as a guest)</li>
                            </ul>
                        <li>Third parties with whom we share your Personal Data</li>
                            <p>3rd party platforms such as for the following purposes:</p>
                            <ul>
                                <li>MongoDB (as a cloud and log-in feature)</li>
                            </ul>
                            <p>Important note: We are not sharing your Personal Data with the food establishments, unless you specifically grant us the permission to do so (e.g., we inform the restaurant that you do not have any dish to offer this person/table, but we do not share the reasons).</p>
                        <li>Consequences of not providing the Personal Data</li>
                            <li>Cannot be able to create an account</li>
                            <li>Cannot allow you to make use of our Service</li>
                            <li>Cannot offer you the relevant dishes and changes based on you preferences</li>
                            <li>Cannot customize your experience</li>
                            <li>Cannot create statistical and aggregated information (de-identified data)</li>
                    </ol>
                    <strong>1.5 When you attend a marketing event, conference and/or we exchange business cards and you provide us with your Personal Data</strong>
                    <ol type="a">
                        <li>Specific Personal Data we collect</li>
                            <ul>
                                    <li>Full name</li>
                                    <li>Email address</li>
                                    <li>Company name</li>
                                    <li>Phone number</li>
                                    <li>Any other information you decide to provide us with</li>
                            </ul>
                        <li>Why is the Personal Data collected and for what purposes?</li>
                            <ul>
                                <li>To communicate with you</li>
                                <li>To establish a business connection with you</li>
                            </ul>
                        <li>Legal basis (GDPR only, if applicable)</li>
                            <ul>
                                <li>Performance of a contract (your acceptance of our terms and conditions <a href="https://www.eat-id.eat.vision//terms-of-use">EAT ID Terms and condition</a>)</li>
                                <li>Legitimate interest (e.g., to allow you to make use of the Service as a guest)</li>
                            </ul>
                        <li>Third parties with whom we share your Personal Data</li>
                            <p>3rd party platforms such as for the following purposes:</p>
                            <ul>
                                <li>Jira (Project management tool)</li>
                                <li>Google Workspace (storage of information)</li>
                            </ul>
                            
                        <li>Consequences of not providing the Personal Data</li>
                            <ul>
                                <li>Cannot communicate with you</li>
                                <li>Cannot establish a business connection with you</li>
                            </ul>
                    </ol>
                    <strong>1.6 When we use the Personal Data of our partner establishments and service providers (e.g. contact details)</strong>
                    <ol type="a">
                        <li>Specific Personal Data we collect</li>
                            <ul>
                                <li>Full name</li>
                                <li>Email address</li>
                                <li>Company name</li>
                                <li>Phone number</li>
                                <li>Establishment name and details (e.g., address)</li>
                                <li>Job position</li>
                                <li>Any other information you decide to provide us with</li>
                            </ul>
                        <li>Why is the Personal Data collected and for what purposes?</li>
                            <ul>
                                <li>To contact our partner establishments</li>
                                <li>To perform the applicable agreement</li>
                                <li>To communicate with the partner establishment</li>
                            </ul>
                        <li>Legal basis (GDPR only, if applicable)</li>
                            <ul>
                                <li>Performance of a contract to which the data subject is party or in order to take steps at the request of the data subject prior to entering into a contract</li>
                                <li>Legitimate interest (e.g., to communicate with the partner or service provider)</li>
                            </ul>
                        <li>Third parties with whom we share your Personal Data</li>
                            <p>3rd party platforms such as for the following purposes:</p>
                            <ul>
                                <li>Jira (Project management tool)</li>
                                <li>Google Workspace (storage of information)</li>
                                <li>MongoDB (cloud)</li>
                                <li>Sendgrid (email provider)</li>
                                <li>Twillio (SMS provider)</li>
                            </ul>
                            
                        <li>Consequences of not providing the Personal Data</li>
                            <ul>
                                <li>Cannot contact our partner establishments</li>
                                <li>Cannot perform the applicable agreement</li>
                                <li>Cannot communicate with the partner establishment</li>
                            </ul>
                    </ol>
                    <strong>1.7 When we use the Personal Data of our service provider (e.g. contact details)</strong>
                    <ol type="a">
                        <li>Specific Personal Data we collect</li>
                            <ul>
                                <li>Full name</li>
                                <li>Email address</li>
                                <li>Company name</li>
                                <li>Phone number</li>
                                <li>Establishment name and details (e.g., address)</li>
                                <li>Job position</li>
                                <li>Any other information you decide to provide us with</li>
                            </ul>
                        <li>Why is the Personal Data collected and for what purposes?</li>
                            <ul>
                                <li>To contact our service provider</li>
                                <li>To perform the applicable agreement</li>
                                <li>To communicate with the service provider</li>
                            </ul>
                        <li>Legal basis (GDPR only, if applicable)</li>
                            <ul>
                                <li>Performance of a contract to which the data subject is party or in order to take steps at the request of the data subject prior to entering into a contract</li>
                                <li>Legitimate interest (e.g., to communicate with the service provider)</li>
                            </ul>
                        <li>Third parties with whom we share your Personal Data</li>
                            <p>3rd party platforms such as for the following purposes:</p>
                            <ul>
                                <li>Jira (Project management tool)</li>
                                <li>Google Workspace (storage of information)</li>
                                <li>MongoDB (cloud)</li>
                                <li>Sendgrid (email provider)</li>
                                <li>Twillio (SMS provider)</li>
                            </ul>
                            
                        <li>Consequences of not providing the Personal Data</li>
                            <ul>
                                <li>Cannot contact our service provider</li>
                                <li>Cannot perform the applicable agreement</li>
                                <li>Cannot communicate with the service provider</li>
                            </ul>
                    </ol>
                    <strong>1.8 When you interact with us on our social media profiles (e.g., LinkedIn)</strong>
                    <ol type="a">
                        <li>Specific Personal Data we collect</li>
                            <ul>
                                <li>Full name</li>
                                <li>Email address</li>
                                <li>Any other information you decide to provide us with</li>
                            </ul>
                        <li>Why is the Personal Data collected and for what purposes?</li>
                            <ul>
                                <li>To reply and/or respond to your request or question</li>
                                <li>To establish a business connection</li>
                            </ul>
                        <li>Legal basis (GDPR only, if applicable)</li>
                            <ul>
                                <li>Depending on the context, our legal basis for collecting this information is the consent you give us for this purpose, legitimate interest (e.g., to send you more information about EAT), and/or for the purposes of pre-contractual discussions</li>
                            </ul>
                        <li>Third parties with whom we share your Personal Data</li>
                            <p>3rd party platforms such as for the following purposes:</p>
                            <ul>
                                <li>Social media channels</li>
                            </ul>
                            
                        <li>Consequences of not providing the Personal Data</li>
                            <ul>
                                <li>Cannot respond to your request</li>
                                <li>Cannot establish a business connection</li>
                            </ul>
                    </ol>
                     
                    <p>Finally, please note that some of the abovementioned Personal Data will be used for detecting, taking steps to prevent, and prosecution of fraud or other illegal activity, to identify and repair errors, to conduct audits, and for security purposes. Personal Data may also be used to comply with applicable laws, with investigations performed by the relevant authorities, law enforcement purposes, and/or to exercise or defend legal claims. In certain cases, we may or will anonymize or de-identify your Personal Data and further use it for internal and external purposes, including, without limitation, to improve the services and for research purposes. “Anonymous Information” means information which does not enable identification of an individual user, such as aggregated information about the use of our services. We may use Anonymous Information and/or disclose it to third parties without restrictions (for example, in order to improve our services and enhance your experience with them). </p>
            </div>
            <div className="section">
                <h2>2. HOW WE PROTECT AND RETAIN YOUR INFORMATION</h2>
                    <li>2.1 Security. We have implemented appropriate technical, organizational and security measures designed to protect your Personal Data. However, please note that we cannot guarantee that the information will not be compromised as a result of unauthorized penetration to our servers. As the security of information depends in part on the security of the computer, device or network you use to communicate with us and the security you use to protect your user IDs and passwords, please make sure to take appropriate measures to protect this information.</li>
                    <li>2.2 Retention of your Personal Data. Your Personal Data will be stored until we delete the record and we proactively delete it or you send a valid deletion request, please note that in some circumstances we may store your Personal Data for longer periods of time, for example (i) where we are required to do so in accordance with legal, regulatory, tax or accounting requirements, or (ii) for us to have an accurate record of your dealings with us in the event of any complaints or challenges, or (iii) if we reasonably believe there is a prospect of litigation relating to your Personal Data or dealings.</li>  
            </div>
            <div className="section">
                <h2>3. HOW WE SHARE YOUR PERSONAL DATA</h2>
                <p>In addition to the recipients described above, we may share your Personal Data as follows:</p>
                    <li>3.1. With our business partners (e.g., restaurant partners) with whom we jointly offer products or Services we may share statistical information, but we do not intend to share any Personal Data.</li>
                    <li>3.2. To the extent necessary, with regulators, courts or competent authorities, to comply with applicable laws, regulations and rules (including, without limitation, federal, state or local laws), and requests of law enforcement, regulatory and other
                    governmental agencies or if required to do so by court order.</li> 
                    <li>3.3. If, in the future, we sell or transfer, or we consider selling or transferring, some or all of our business, shares or assets to a third party, we will disclose your Personal Data to such third party (whether actual or potential) in connection with the foregoing
                    events.</li>  
                    <li>3.4. In the event that we are acquired by, or merged with, a third party entity, or in the event of bankruptcy or a comparable event, we reserve the right to transfer, disclose or assign your Personal Data in connection with the foregoing events, including, in
                    connection with, or during negotiations of, any merger, sale of company assets, consolidation or restructuring, financing, or acquisition of all or a portion of our business by or to another company; and/or.</li>  
                    <li>3.5. Where you have provided your consent to us sharing or transferring your Personal Data (e.g., where you provide us with marketing consents or opt-in to optional additional services or functionality).</li>   

                <p>If you want to receive the list of the current recipients of your Personal Data, please make your request by contacting us to <a href="mailto:privacy@eat.vision">privacy@eat.vision</a>.</p>
            </div>
            <div className="section">
                <h2>4. ADDITIONAL INFORMATION REGARDING TRANSFERS OF PERSONAL DATA</h2>
                    <li>4.1. Where we transfer your Personal Data outside of your jurisdiction (for example to third parties who provide us with Service), we will generally obtain contractual commitments from them to protect your Personal Data. When EAT engages in such transfers of personal information, EAT will use commercially reasonable efforts to have in place contracts with such service providers to protect your data.</li>
            </div>
            <div className="section">
                <h2>5. YOUR PRIVACY RIGHTS. HOW TO DELETE YOUR ACCOUNT</h2>
                    <li>5.1. Rights: The following rights (which may be subject to certain exemptions or derogations) shall apply to certain individuals (some of which only apply to individuals protected by the GDPR):</li>
                        <li>(a) You have a right to access Personal Data held about you. Your right of access may normally be exercised free of charge, however we reserve the right to charge an appropriate administrative fee where permitted by applicable law;</li>
                        <li>(b) You have the right to request that we rectify any Personal Data we hold that is inaccurate or misleading;</li>
                        <li>(c) You have the right to request the erasure/deletion of your Personal Data (e.g. from our records). Please note that there may be circumstances in which we are required to retain your Personal Data, for example for the establishment, exercise or defense of legal claims;</li>
                        <li>(d) You have the right to object, to or to request restriction, of the processing;</li>
                        <li>(e) You have the right to data portability. This means that you may have the right to receive your Personal Data in a structured, commonly used and machine-readable format, and that you have the right to transmit that data to another controller;</li>
                        <li>(f) You have the right to object to profiling;</li>
                        <li>(g) You have the right to withdraw your consent at any time. Please note that there may be circumstances in which we are entitled to continue processing your data, in particular if the processing is required to meet our legal and regulatory obligations.
                        Also, please note that the withdrawal of consent shall not affect the lawfulness of processing based on consent before its withdrawal;</li>
                        <li>(h) You also have a right to request certain details of the basis on which your Personal Data is transferred outside the European Economic Area, but data transfer agreements and/or other details may need to be partially redacted for reasons of
                        commercial confidentiality;</li>
                        <li>(i) You have a right to lodge a complaint with your local data protection supervisory authority (i.e., your place of habitual residence, place or work or place of alleged infringement) at any time or before the relevant institutions in your place of
                        residence. We ask that you please attempt to resolve any issues with us before you contact your local supervisory authority and/or relevant institution.</li>
                    <li>5.2. You can exercise your rights by contacting us to <a href="mailto:privacy@eat.vision">privacy@eat.vision</a>. You may use an authorized agent to submit a request on your behalf if you provide the authorized agent written permission signed by you. To protect your privacy, we may take steps to verify your identity before fulfilling your request. Subject to legal and other permissible considerations, we will make every reasonable effort to honor your request promptly in accordance with applicable law or inform you if we require further information in order to fulfill your request. When processing your request, we may ask you for additional information to confirm or verify your identity and for security purposes, before processing and/or honoring your request. We reserve the right to charge a fee where permitted by law, for instance if your request is manifestly unfounded or excessive. In the event that your request would adversely affect the rights and freedoms of others (for example, would impact the duty of confidentiality we owe to others) or if we are legally entitled to deal with your request in a different way than initial requested, we will address your request to the maximum extent possible, all in accordance with applicable law.</li> 
                    <li>5.3. Deleting your account: Should you ever decide to delete your account, you may do so by emailing <a href="mailto:privacy@eat.vision">privacy@eat.vision</a>. If you terminate your account, any association between your account and Personal Data we store will no longer be accessible through your account. However, given the nature of sharing on certain services, any public activity on your account prior to deletion will remain stored on our servers and will remain accessible to the public.</li>  

            </div>
            <div className="section">
                <h2>6. USE BY CHILDREN</h2>
                <p>We do not offer our products or services for use by children and, therefore, we do not knowingly collect Personal Data from, and/or about children under the age of eighteen (18). If you are under the age of eighteen (18), do not provide any Personal Data to us without involvement of a parent or a guardian. For the purposes of the GDPR, we do not intend to offer information society services directly to children. In the event that we become aware that you provide Personal Data in violation of applicable privacy laws, we reserve the right to delete it. If you believe that we might have any such information, please contact us at <a href="mailto:privacy@eat.vision">privacy@eat.vision</a>.</p>
            </div>
            <div className="section">
                <h2>7. INTERACTION WITH THIRD PARTY PRODUCTS</h2>
                <p>We enable you to interact with third party websites, mobile software applications and products or services that are not owned or controlled by us (each a “Third Party Service”). We are not responsible for the privacy practices or the content of such Third Party Services. Please be aware that Third Party Services can collect Personal Data from you. Accordingly, we encourage you to read the terms and conditions and privacy policies of each Third Party Service.</p>
            </div>
            <div className="section">
                <h2>8. LOG FILES</h2>
                <p>We use log files. We use such information to analyze trends, administer the Website, track users’ movement around the Website, and gather demographic information.
                </p>
            </div>
            <div className="section">
                <h2>9. ANALYTIC TOOLS</h2>
                    <li>9.1 Google Analytics. The Website and Services uses a tool called “Google Analytics” to collect information about use of the Website and Services. Google Analytics collects information such as how often users visit this Website and Services, what pages
                    they visit when they do so, and what other websites they used prior to coming to this Website and Services. We use the information we get from Google Analytics to maintain and improve the Website and Services. We do not combine the information collected through the use of Google Analytics with Personal Data we collect. Google’s ability to use and share information collected by Google Analytics about your visits to this Website and Services is restricted by the Google Analytics Terms of Service, available at <a href="https://marketingplatform.google.com/about/analytics/terms/us/">https://marketingplatform.google.com/about/analytics/terms/us/</a>, and the Google Privacy Policy, available at <a href="http://www.google.com/policies/privacy/">http://www.google.com/policies/privacy/</a>. You may learn more about how Google collects and processes data specifically in connection with Google Analytics at <a href="http://www.google.com/policies/privacy/">http://www.google.com/policies/privacy/</a>partners/. You may prevent your data from being used by Google Analytics by downloading and installing the Google Analytics Opt-out Browser Add-on, available at <a href="https://tools.google.com/dlpage/gaoptout/">https://tools.google.com/dlpage/gaoptout/</a>.</li>
                    <li>9.2 We reserve the right to remove or add new analytic tools.</li>  
            </div>
            <div className="section">
                <h2>10. SPECIFIC PROVISIONS APPLICABLE UNDER CALIFORNIA LAW</h2>
                    <li>10.1. Our California Do Not Track Notice: Do Not Track (“DNT”) is a privacy preference that users can set in certain web browsers. Please note that we do not respond to or honor DNT signals or similar mechanisms transmitted by web browsers, but we may allow third parties, such as companies that provide us with analytics tools, to collect personally identifiable information about an individual consumer’s online activities over time and across different web sites when a consumer uses the Services.</li>
            </div>
            
            <div className="section">
                <h2>11. CONTACT US</h2>
                <p>If you have any questions, concerns or complaints regarding our compliance with this notice and the data protection laws, or if you wish to exercise your rights, we encourage you to first contact us at <a href="mailto:privacy@eat.vision">privacy@eat.vision</a>.</p>
                <p>Data controller: EAT ID Ltd.</p>
            </div>
    </div>
    )
}

export default PrivacyPolicy;