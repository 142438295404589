import React from 'react'
import './termsAndPolicy.css'


const TermsOfUse= () => {
    return (
        <div className="privacy-policy">
            <h1 className="align-center">EAT ID Terms Of Use</h1>
            <p className="align-center">Last Updated: February 5 2023</p>

            <div className="section">
                <p>Welcome to <a href="https://www.eat.vision/" target="_blank" rel="noopener noreferrer">https://www.eat.vision</a> (together with its subdomains, Content, Marks and services, the “Website”). Please
read the following Website Terms of Use carefully before using this Website so that you are aware of your legal rights
and obligations with respect to EAT ID Ltd. (“EAT”, “we”, “our” or “us”). By accessing or using the Website, you
expressly acknowledge and agree that you are entering a legal agreement with us and have understood and agree to
comply with, and be legally bound by these Website Terms of Use, together with our Privacy Policy (“<b>Terms</b>”). You
hereby waive any applicable rights to require an original (non-electronic) signature or delivery or retention of
non-electronic records, to the extent not prohibited under applicable law. If you do not agree to be bound by these Terms
please do not access or use the Website.</p>
                <ol type="1">
                    <li><strong>Background.</strong> The Website is intended to inform you about EAT ID Ltd. and its services products and services.</li>
                    <li><strong>Modification.</strong> We reserve the right, at our discretion, to change these Terms at any time. Such change will be
effective 5 days following posting of the revised Terms on the Website, and your continued use of the Website
thereafter means that you accept those changes.</li>
                    <li><strong>Ability to Accept Terms.</strong> The Website is only intended for individuals aged 18 years or older. If you are under
18 years please do not visit or use the Website.</li>
                    <li><strong>Website Access.</strong> For such time as these Terms are in force and effect, we hereby grant you permission to visit
and use the Website provided that you comply with these Terms and applicable law.</li>
                    <li><strong>Restrictions.</strong> You shall not: (i) copy, distribute or modify any part of the Website without our prior written
authorization; (ii) use, modify, create derivative works of, transfer (by sale, resale, license, sublicense, download
or otherwise), reproduce, distribute, display or disclose Content (defined below), except as expressly authorized
herein; (iii) disrupt servers or networks connected to the Website; (iv) use or launch any automated system
(including without limitation, “robots” and “spiders”) to access the Website; and/or (v) circumvent, disable or
otherwise interfere with security-related features of the Website or features that prevent or restrict use or copying
of any Content or that enforce limitations on use of the Website.</li>
                    <li><strong>Account.</strong> In order to use some of the services of the Website, you may have to create an account (“<b>Account</b>”).
You agree not to create an Account for anyone else or use the account of another without their permission.
When creating your Account, you must provide accurate and complete information. You are solely responsible
for the activity that occurs in your Account, and you must keep your Account password secure. You must notify
EAT immediately of any breach of security or unauthorized use of your Account. As between you and EAT, you
are solely responsible and liable for the activity that occurs in connection with your Account. If you wish to
delete your Account you may send an email request to EAT at support@eat.vision.</li>
                    <li><strong>Intellectual Property Rights</strong>
                        <ol type="7.1">
                            <li><underline>Content and Marks.</underline> The: (i) content on the Website, including without limitation, the text, documents, articles,
                    brochures, descriptions, products, software, graphics, photos, sounds, videos, interactive features, and services
                    (collectively, the “Content”); and (iii) the trademarks, service marks and logos contained therein (“Marks”), are
                    the property of EAT and/or its licensors and may be protected by applicable copyright or other intellectual
                    property laws and treaties. “EAT”, the EAT logo, and other marks are Marks of EAT or its affiliates. All other
                    trademarks, service marks, and logos used on the Website are the trademarks, service marks, or logos of their
                    respective owners. We reserve all rights not expressly granted in and to the Website and the Content.</li>
                    <li><underline>Use of Content.</underline> Content on the Website is provided to you for your information and personal use only and may
                    not be used, modified, copied, distributed, transmitted, broadcast, displayed, sold, licensed, de-compiled, or
                    otherwise exploited for any other purposes whatsoever without our prior written consent. If you download or
                    print a copy of the Content you must retain all copyright and other proprietary notices contained therein.</li>
                        </ol>
                    </li>
                    <li><strong>Information Description.</strong> We attempt to be as accurate as possible. However, we cannot and do not warrant that
                    the Content available on the Website is accurate, complete, reliable, current, or error-free. We reserve the right to
                    make changes in or to the Content, or any part thereof, in our sole judgment, without the requirement of giving
                    any notice prior to or after making such changes to the Content. Your use of the Content, or any part thereof, is
                    made solely at your own risk and responsibility.</li>
                    <li><strong>Disclosure.</strong>We reserve the right to access, read, preserve, and disclose any information that we obtain in
                    connection with the Website, and your use thereof, as we reasonably believe is necessary to: (i) satisfy any
                    applicable law, regulation, legal process, subpoena or governmental request; (ii) enforce these Terms, including
                    to investigate potential violations of them; (iii) detect, prevent or otherwise address fraud, security or technical
                    issues; (iv) respond to user support requests; or (v) protect the rights, property or safety of EAT, its users or the
                    public.</li>
<li><strong> Links</strong>
        <ol type="10.1">
            <li>The Website may contain links, and may enable you to post content, to third party websites that are not owned or
controlled by EAT. We are not affiliated with, have no control over, and assume no responsibility for the content,
privacy policies, or practices of, any third party websites. You: (i) are solely responsible and liable for your use
of and linking to third party websites and any content that you may send or post to a third party website; and (ii)
expressly release EAT from any and all liability arising from your use of any third party website. Accordingly,
we encourage you to read the terms and conditions and privacy policy of each third party website that you may
choose to visit.</li>
<li>EAT permits you to link to the Website provided that: (i) you link to but do not replicate any page on this
Website; (ii) the hyperlink text shall accurately describe the Content as it appears on the Website; (iii) you shall
not misrepresent your relationship with EAT or present any false information about EAT and shall not imply in
any way that we are endorsing any services or products, unless we have given you our express prior consent; (iv)
you shall not link from a website (“Third Party Website”) which prohibites linking to third parties; (v) such
Third party Website does not contain content that (a) is offensive or controversial (both at our discretion), or (b)
infringes any intellectual property, privacy rights, or other rights of any person or entity; and/or (vi) you, and
your website, comply with these Terms and applicable law.</li></ol></li>
<li><strong>Privacy</strong>Our privacy policy which is available here EAT ID privacy policy</li>
<li><strong>Warranty Disclaimers</strong>
<ol type="12.1">
<li>This section applies whether or not the services provided under the Website are for payment. Applicable law may
not allow the exclusion of certain warranties, so to that extent certain exclusions set forth herein may not apply.</li>
<li>THE WEBSITE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, AND WITHOUT
WARRANTIES OF ANY KIND EITHER EXPRESS OR IMPLIED. EAT HEREBY DISCLAIMS ALL
WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES
OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT,
AND THOSE ARISING BY STATUTE OR FROM A COURSE OF DEALING OR USAGE OF TRADE. EAT
DOES NOT GUARANTEE THAT THE WEBSITE WILL BE FREE OF BUGS, SECURITY BREACHES, OR
VIRUS ATTACKS. THE WEBSITE MAY OCCASIONALLY BE UNAVAILABLE FOR ROUTINE
MAINTENANCE, UPGRADING, OR OTHER REASONS. YOU AGREE THAT EAT WILL NOT BE HELD
RESPONSIBLE FOR ANY CONSEQUENCES TO YOU OR ANY THIRD PARTY THAT MAY RESULT
FROM TECHNICAL PROBLEMS OF THE INTERNET, SLOW CONNECTIONS, TRAFFIC CONGESTION
OR OVERLOAD OF OUR OR OTHER SERVERS. WE DO NOT WARRANT, ENDORSE OR GUARANTEE
ANY CONTENT, PRODUCT, OR SERVICE THAT IS FEATURED OR ADVERTISED ON THE WEBSITE
BY A THIRD PARTY.</li>
<li> EXCEPT AS EXPRESSLY STATED IN OUR PRIVACY POLICY, EAT DOES NOT MAKE ANY
REPRESENTATIONS, WARRANTIES OR CONDITIONS OF ANY KIND, EXPRESS OR IMPLIED, AS TO
THE SECURITY OF ANY INFORMATION YOU MAY PROVIDE OR ACTIVITIES YOU ENGAGE IN
DURING THE COURSE OF YOUR USE OF THE WEBSITE.</li>
</ol></li>
<li><strong> Limitation of Liability</strong>
<ol type="13.1">
<li>TO THE FULLEST EXTENT PERMISSIBLE BY LAW, EAT SHALL NOT BE LIABLE FOR ANY DIRECT,
INDIRECT, EXEMPLARY, SPECIAL, CONSEQUENTIAL, OR INCIDENTAL DAMAGES OF ANY KIND,
OR FOR ANY LOSS OF DATA, REVENUE, PROFITS OR REPUTATION, ARISING UNDER THESE
TERMS OR OUT OF YOUR USE OF, OR INABILITY TO USE, THE WEBSITE, EVEN IF EAT HAS BEEN
ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES. Some jurisdictions do not allow the
limitation or exclusion of liability for incidental or consequential damages, so the above limitations may not
apply to you.</li>
<li > IN NO EVENT SHALL THE AGGREGATE LIABILITY OF EAT FOR ANY DAMAGES ARISING UNDER
THESE TERMS OR OUT OF YOUR USE OF, OR INABILITY TO USE, THE WEBSITE EXCEED THE
TOTAL AMOUNT OF FEES, IF ANY, PAID BY YOU TO EAT FOR USING THE WEBSITE DURING THE
3 MONTHS PRIOR TO BRINGING THE CLAIM.</li>
</ol></li>
<li><strong>Indemnity.</strong>  You agree to defend, indemnify and hold harmless EAT and our affiliates, and our respective
officers, directors, employees and agents, from and against any and all claims, damages, obligations, losses,
liabilities, costs and expenses (including but not limited to attorney’s fees) arising from: (i) your use of, or
inability to use, the Website; (ii) your interaction with any Website user; or (iii) your violation of these Terms.</li>
<li><strong>Term and Termination.</strong> These Terms are effective until terminated by EAT or you. EAT, in its sole discretion,
has the right to terminate these Terms and/or your access to the Website, or any part thereof, immediately at any
time and with or without cause (including, without any limitation, for a breach of these Terms). EAT shall not be
liable to you or any third party for termination of the Website, or any part thereof. If you object to any term or
condition of these Terms, or any subsequent modifications thereto, or become dissatisfied with the Website in
any way, your only recourse is to immediately discontinue use of the Website. Upon termination of these Terms,
you shall cease all use of the Website. This Section 14 and Sections 6 (Intellectual Property Rights), 10
(Privacy), 11 (Warranty Disclaimers), 12 (Limitation of Liability), 13 (Indemnity), and 15 (Independent
Contractors) to 18 (General) shall survive termination of these Terms.</li>
<li><strong>Independent Contractors.</strong> You and EAT are independent contractors. Nothing in these Terms creates a
partnership, joint venture, agency, or employment relationship between you and EAT. You must not under any
circumstances make, or undertake, any warranties, representations, commitments or obligations on behalf of
EAT.</li>
<li><strong>Assignment.</strong> These Terms, and any rights and licenses granted hereunder, may not be transferred or assigned by
you but may be assigned by EAT without restriction or notification to you. Any prohibited assignment shall be
null and void.</li>
<li><strong>Governing Law.</strong> These Terms and the relationship between you and EAT shall be governed by and construed in
accordance with the laws of the State of Israel, without regard to its principles of conflict of laws. You agree to
submit to the personal and exclusive jurisdiction of the courts located in Tel Aviv-Jaffa and waive any
jurisdictional, venue, or inconvenient forum objections to such courts, provided that EAT may seek injunctive
relief in any court of competent jurisdiction.</li>
<li><strong>General.</strong> These Terms shall constitute the entire agreement between you and EAT concerning the Website. If any
provision of these Terms is deemed invalid by a court of competent jurisdiction, the invalidity of such provision
shall not affect the validity of the remaining provisions of these Terms, which shall remain in full force and
effect. No waiver of any term of these Terms shall be deemed a further or continuing waiver of such term or any
other term, and a party’s failure to assert any right or provision under these Terms shall not constitute a waiver of
such right or provision. TO THE MAXIMUM EXTENT PERMITTED BY LAW, YOU AGREE THAT ANY
CAUSE OF ACTION THAT YOU MAY HAVE ARISING OUT OF OR RELATED TO THE WEBSITE MUST
COMMENCE WITHIN 1 YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH
CAUSE OF ACTION IS PERMANENTLY BARRED.</li>
</ol>
</div>
        </div>
                    
    )
}

export default TermsOfUse;